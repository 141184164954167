/* ==========================================================================
  c-blogpost-author
   ========================================================================== */

.c-blogpost-author {
  display: flex;
  background: $g-gray-50;
  padding: 2.4rem;
  margin-top: 3.2rem;
}

.c-blogpost-author__img {
  border-radius: 100%;
  width: 7.2rem;
  height: 7.2rem;
  margin-right: 2.4rem;
  flex: 0 0 auto;
}

.c-blogpost-author__name.c-blogpost-author__name.c-blogpost-author__name { /* override c-content */
  /* dirty but just a quick fix before launch, to actually fix, please don't let .c-blogpost-author be a child of .c-content*/
  margin: 0 0 1.2rem;
}

.c-blogpost-author .c-horizontal-list { /* override c-content */
  margin: 0;
  li {
    list-style: none;
  }
}
