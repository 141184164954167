/* ==========================================================================
   c-sidebar-nav
   ========================================================================== */

.c-sidebar-nav li {
  list-style: none;
  margin: 0 0 0.6rem;
  &:last-child {
    margin: 0;
  }
}

.c-sidebar-layout__side nav {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 2rem;
  overflow-y: auto
}

.c-sidebar-nav {

  a {
    text-decoration: none;
    display: block;
    padding: 1rem 1.6rem;
    &:link,
    &:visited {
      color: #1D2C48;
    }
    &:hover,
    &:active,
    &:focus {
      color: $g-link-color;
    }
  }
}

.c-sidebar-nav__item a {
  border-radius: .8rem;
  &:hover,
  &:focus {
    color: $g-pink-700;
    background: #FFF6FF;
  }
  &::first-letter { text-transform: capitalize }
}

.c-sidebar-nav .c-sidebar-nav__item--selected > a {
  background: #FFE3FF;
  &:link,
  &:visited {
    color: $g-pink-700;
  }
}


/* ==========================================================================
   c-sidebar-nav-child
   2nd level
   ========================================================================== */

.c-sidebar-nav-child {
  margin: 0.6rem 0 0.6rem 1.6rem;
  padding-left: 1.6rem;
  border-left: 0.2rem solid #E938C2;
}

.c-sidebar-nav-child li {
  list-style: none;
  margin: 0 0 0.6rem;
  &:last-child {
    margin: 0;
  }
}

.c-sidebar-nav-child {
  a {
    text-decoration: none;
    display: block;
    color: $g-pink-700;
    padding: 0.8rem 1.6rem;

    &:hover,
    &:focus {
      color: $g-pink-500;
    }
  }
}

.c-sidebar-nav-child__item a {
  border-radius: .8rem;
  &:hover,
  &:focus {
    color: $g-pink-700;
    background: #FFF6FF;
  }
}

.c-sidebar-nav-child .c-sidebar-nav-child__item--selected > a {
  background: #FFF0FF;
  color: $g-pink-700;
}

