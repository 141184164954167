/* ==========================================================================
   s-variables
   Prefixed with $g-
   ========================================================================== */

// Grays
$g-gray-50: #F3F6FA;
$g-gray-100: #DEE3EC;
$g-gray-200: #D1D7E2;
$g-gray-300: #B2BACC;
$g-gray-400: #8D97AA;
$g-gray-500: #636C7E;
$g-gray-600: #4C5567;
$g-gray-800: #1D2C48;

// Pinks
$g-pink-50: #FFF6FF;
$g-pink-100: #FBE4FD;
$g-pink-500: #E938C2;
$g-pink-700: #BD239B;

// Purples
$g-purple-400: #AD62BF;
$g-purple-600: #A60DCC;

// Aqua
$g-aqua-100: #D7F7FF;
$g-aqua-500: #0DA5CC;

// Blues
$g-blue-200: #B6C8F5;
$g-blue-400: #87A1E3;
$g-blue-500: #5979CC;
$g-blue-600: #3F5EAA;

/* Semantic links to colors
   ========================================================================== */

$g-heading-color: $g-gray-800;
$g-text-color: $g-gray-600;
$g-divider-color: $g-gray-200;
$g-primary-color: $g-pink-500;
$g-link-color: $g-blue-600;

/* Type
   ========================================================================== */

$g-headings-font-family: "Overpass", sans-serif;
$g-text-font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

/* Breakpoints
   ========================================================================== */

$g-bp-1: 500px;
$g-bp-2: 900px;

/* Components
   =========================================================================== */

$g-table-border-color: $g-gray-200;