/* ==========================================================================
  Swatch (for styleguide)
  ========================================================================== */

.c-styleguide-color-swatch {
  flex: 0 0 auto;
  text-align: center;
  min-width: 12rem;
  margin: 2.4rem;
}

.c-styleguide-color-swatch__line2 {
  font-size: 1.3rem;
  color: $g-gray-400;
  @media (prefers-color-scheme: dark) {
    color: $g-gray-600;
  }
}

.c-styleguide-color-swatch__self {
  background: #666;
  margin: 0 auto 1.6rem;
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 100%;
}