/* ==========================================================================
  Component: table
  ---
  Table styling
   ========================================================================== */

/* Variables
   ========================================================================== */

$c-table-background-color: #FFF !default;
$c-table-row-odd-color: #EEE !default;

/* Component
   ========================================================================== */

.c-table {
  width: 100%;

  th, td {
    text-align: left;
  }

  td,
  th {
    text-align: left;
    padding: 0.8rem 0;
    vertical-align: top;
    position: relative;
  }

  th {
    font-weight: 500;
  }

}

.c-table__row--alt td {
  background: #FFF4FF;
}

.c-table--styled {
  margin: 0 0 2.4rem;

  th {
    font-weight: 500;

  }

  th {
    border-bottom: 0.2rem solid $g-table-border-color;
    vertical-align: top;
  }

  td {
    border-bottom: 0.1rem solid $g-table-border-color;
  }

  tr:last-child {
    td {
      border: none;
    }
  }

  td,
  th {
    text-align: left;
    padding: 1.6rem;
    position: relative;
  }

  td .c-button,
  th .c-button {
    margin-bottom: -0.8rem;
    margin-top: -0.8rem;
  }

  td input[type=checkbox]{
    margin: 0 auto;
  }
}

.c-table--bordered {
  border: 0.1rem solid $g-table-border-color;
  border-radius: 0.3rem;
  border-collapse: separate;
}

.c-table--nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.c-table--align-middle {
  td {
    vertical-align: middle;
  }
}

.c-table--striped {
  tbody tr:nth-child(even) {
    background: $c-table-row-odd-color;
  }
}

.c-table--no-margin {
  margin: 0;
}

.c-table--align-middle {
  td {
    vertical-align: middle;
  }
}

.c-table.c-table--horizontal {
  th {
    width: 18rem;
    border-width: 0.1rem;
  }
  tr:last-child {
    th {
      border: none;
    }
  }
}

/* Invisible table structure
   ========================================================================== */

.c-table.c-table--invisible {
  border: 0;
  width: 100%;

  tr th,
  tr td {
    border: 0;
    padding-left: 0;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
}

/* Untable a table
   ========================================================================== */

.c-table--untable {
  tr {
    display: block;
    th {
      display: block;
    }
    td {
      display: block;
    }
  }
  @media (min-width: $g-bp-2) {
    tr {
      display: table-row;
      th {
        display: table-cell;
      }
      td {
        display: table-cell;
      }
    }
  }
}

/* Responsive table
   Adapted from Bootstrap 4
   ========================================================================== */

.c-table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057

  // Prevent double border on horizontal scroll due to use of `display: block;`
  > .c-table--bordered {
    border: 0;
  }
}
