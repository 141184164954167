/* ==========================================================================
  c-header
  ---
  The general header
   ========================================================================== */

.c-header {
  padding: 2.4rem 0;
  > .c-container-horizontal {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
