/* ==========================================================================
  u-spacer
   ========================================================================== */

.u-spacer {
  padding: 1.6rem;
}

.u-spacer-bottom--small {
  padding-bottom: 0.8rem;
}
