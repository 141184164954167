/* ==========================================================================
  c-container-vertical
   ========================================================================== */

.c-container-vertical {
  padding: 2.4rem 0;

  @media (min-width: $g-bp-2) {
    padding: 3.2rem 0;
  }
}

.c-container-vertical--small {
  padding: 1.6rem;
  @media (min-width: $g-bp-2) {
    padding: 2.4rem 0;
  }
}
