/* ==========================================================================
  c-usps
   ========================================================================== */

.c-usps__item {
  margin: 0 0 3.2rem;
  @media (min-width: $g-bp-2) {
    flex: 1 0 29%;
    padding-right: 4.8rem;
  }
}

@media (min-width: $g-bp-2) {

  .c-usps {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

}
