/* ==========================================================================
  e-base
  ---
  Only base element styling
   ========================================================================== */

*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

html {
  scroll-behavior: smooth;
  font-size: 50%;
  @media (min-width: $g-bp-2) {
    font-size: 62.5%;
  }
}

body {
  border-top: 0.4rem solid #E938C2;
  background: #FFF;
  color: $g-text-color;
  font-family: $g-text-font-family;
  line-height: 1.7;
  font-size: 1.6rem;
}

button, input {
  font-family: $g-text-font-family;
}