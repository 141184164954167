.c-blogpost-list {
  max-width: 80rem;
  margin: 0 auto;
}

.c-blogpost-list__item {
  margin-bottom: 6.4rem;
  padding-bottom: 6.4rem;
  border-bottom: 0.1rem solid $g-divider-color;
  &:last-child {
    margin: 0;
    border: none;
  }
}
