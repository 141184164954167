/* ==========================================================================
  c-horizontal-list
   ========================================================================== */

.c-horizontal-list {
  display: flex;
  > li {
    list-style: none;
    margin-right: 0.8rem;
    @include link-styles(false);
  }
}

.c-horizontal-list--bordered > li {
  padding-right: 0.8rem;
  border-right: 0.1rem solid $g-gray-300;
  &:last-child {
    border-right: none;
  }
}

.c-horizontal-list--icons > li a {
  display: flex;
  &:link, &:visited {
    border: none;
    color: $g-gray-600
  }
  &:hover, &:active {
    border-bottom: 0.1rem solid $g-gray-300;
    color: $g-gray-800;
    .o-svg-icon {
      opacity: 0.7;
    }
  }
  .o-svg-icon {
    opacity: 0.5;
    position: relative;
    top: 0.3rem;
    margin-right: 0.4rem;
  }
}