/* ==========================================================================
  c-button
   ========================================================================== */

/* Variables
   ========================================================================== */

$c-button-primary-color: #e938c2 !default;
$c-button-link-color: $c-button-primary-color !default;

/* Component
   ========================================================================== */

.c-button {
  padding: 0.8rem 1.2rem;
  display: inline-flex;
  border-radius: 0.2rem;
  border: none;
  appearance: none;
  border-radius: 0.6rem;
  font-size: 1.6rem;
  align-items: center;
  font-family: $g-text-font-family;
  @at-root a#{&} {
    text-decoration: none;
  }
  .o-svg-icon {
    * {
      fill: currentColor;
    }
  }
  // Space between button and icon
  .c-button__icon-wrap:first-child {
    margin-right: 0.4rem;
  }
  .c-button__icon-wrap:last-child {
    margin-left: 0.4rem;
  }
  
}

/* Skin variants
 ========================================================================== */

// Primary

.c-button--primary {
  background: $c-button-primary-color;
  color: #fff;
  &:hover,
  &:focus {
    background: lighten($c-button-primary-color, 10%);
  }
  &:active {
    background: darken($c-button-primary-color, 10%);
  }
}

// Outline

.c-button--outline {
  padding: calc(0.8rem - 0.1rem) calc(1.2rem - 0.1rem);
  border: 0.1rem solid $g-gray-300;
  color: $g-gray-600;
  .svg-icon {
    * {
      fill: $g-gray-600;
    }
    margin: 0.2rem 0.6rem 0 0;
  }
  &:hover {
    border-color: $g-gray-600;
  }
}

/* Size variants
 ========================================================================== */

.c-button--large {
  padding: 1.2rem 2.4rem;
}

.c-button--small {
  font-size: 1.4rem;
  padding: 0.6rem 1rem;
}