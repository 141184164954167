/* ==========================================================================
  c-blogpost-layout  
   ========================================================================== */

.c-blogpost-layout {
  max-width: 80vw;
  @media (min-width: 1024px) {
    max-width: 80rem;
  }
  margin: 0 auto;
}
