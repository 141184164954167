/* ==========================================================================
  Button toolbar
  ========================================================================== */

.c-button-toolbar {
  list-style: none;
  padding: 0;
  display: flex;
  .c-button {
    margin-right: 0.4rem;
  }
}

.c-button-toolbar .c-button:last-child {
  margin-right: 0;
}