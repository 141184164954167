/* ==========================================================================
  c-note
   ========================================================================== */

.c-note {
  margin: 1.6rem 0;
  background: #FBE4FD;
  padding: 2.4rem;
  position: relative;
  display: flex;
  color: #1D2C48;

  p:last-child {
    margin: 0;
  }
}

.c-note__icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: middle;
  margin-top: .2rem;
  margin-right: .8rem;
  opacity: 0.5;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  @media (min-width: $g-bp-2) {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.2rem;
  }
}

.c-note__text {
  width: 95%;
}

.c-note:after {
  content: ' ';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 2rem 2rem 0;
  border-color: transparent #F7D1FA transparent transparent;
  position: absolute;
  top: 0;
  right: 0;
}

.c-note > *:last-child {
  margin-bottom: 0;
}

.c-note code {
  background: #F9D5FC;
}

.c-note--alternate {
  background: #E2F9FF;

  &:after {
    border-color: transparent #B7E4EF transparent transparent;
  }
}

.c-note--warning {
  background: #FFD7D7;

  svg path {
    fill: #BE1515;
  }

  &:after {
    border-color: transparent #F1C4C4 transparent transparent;
  }

  code {
    background: #FFCCCC;
  }
}
