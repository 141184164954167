/* ==========================================================================
  c-function-doc
   ========================================================================== */

/* Hash link */
.c-function-doc__header a {
  color: #0DA5CC;
  text-decoration: none;
  display: inline-block;
  height: 2.4rem;
  width: 2.4rem;
  text-align: center;
  line-height: 2.4rem;
  border-radius: 0.3rem;
  font-size: 90%;
}

.c-function-doc__header a:hover {
  color: #0DA5CC;
  background: #D7F7FF;
  text-decoration: none;
  transition: 0.18s background ease-in-out;
}

.c-function-doc__content {
  padding-left: 1.6rem;
}

.c-function-doc__heading {
  font-size: 2.4rem;
  margin: 0 0 1.6rem;
}