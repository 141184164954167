/* ==========================================================================
  File tree
  ---
  Illustration on home page in HTML
   ========================================================================== */

.c-file-tree .c-file-tree {
  padding-left: 3.2rem;
}

.c-file-tree li {
  margin: 0.4rem 0;
}

.c-file-tree__icon-wrap {
  margin-right: 1.2rem;
}

.c-file-tree__filename {
  width: 14rem;
}
