/* ==========================================================================
  c-logo
   ========================================================================== */

.c-logo {
  display: flex;
  width: 12rem;

  img {
    width: 100%;
  }

  @media (min-width: $g-bp-2) {
    width: 17.2rem;
  }
}
