/* ==========================================================================
  c-styleguide-icon  
   ========================================================================== */

.c-styleguide-icon {
  display: flex;
  margin: 0.4rem 0;
}

.c-styleguide-icon__wrap {
  min-width: 4rem;
}

.c-styleguide-icon__name {
  
}
