/* ==========================================================================
  c-sidebar-layout
   ========================================================================== */

.c-sidebar-layout {
  margin: 3.6rem 0;
}

.c-sidebar-layout__side {
  margin: 0 0 2.4rem;
}

@media (max-width: $g-bp-2) {
  .c-sidebar-layout__side {
    display: none;
  }
}

@media (min-width: $g-bp-2) {
  .c-sidebar-layout {
    display: flex;
  }

  .c-sidebar-layout__side {
    flex: 0 0 20%;
    margin-right: 6.4rem;
  }

  .c-sidebar-layout__main {
    > .c-container-vertical:first-child {
      padding: 0;
    }
    flex: 1 1 auto;
    overflow-x: auto;
  }
}
