/* ==========================================================================
  c-tabs
   ========================================================================== */

.c-tabs {
  border-bottom: 0.1rem solid $g-gray-200;
}

.c-tabs button {
  padding: .8rem 1.6rem;
  background: #FFF;
  color: $g-primary-color;
  border: none;
  font-family: $g-text-font-family;
  font-size: 1.6rem;
  font-weight: 500;
  position: relative;
  top: 0.1rem;

  &:hover,
  &:focus {
    border-bottom: 0.2rem solid $g-primary-color;
    color: $g-text-color;
    outline-style: none;
  }
}

.c-tabs {
  .selected {
    border-bottom: 0.2rem solid $g-primary-color;
    color: $g-text-color;
  }
}
