/* ==========================================================================
  c-footer
   ========================================================================== */

.c-footer {
  border-top: 0.1rem solid $g-divider-color;
  padding: 1.6rem 0;
  
  margin-top: 2.4rem;

  @media (min-width: $g-bp-2) {
    margin-top: 4.8rem;
    padding: 1.6rem 3.2rem;
  }
}
