/* PrismJS 1.17.1
https://prismjs.com/download.html#themes=prism&languages=markup+css+clike+javascript */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */

/* Regular code blocks
   ========================================================================== */

code {
  padding: 0.2rem;
  color: #CE2F55;
  background: #FFF6FF;
  font-size: 1.4rem;
  font-family: "Source Code Pro", Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
}

code[class*="language-"],
pre[class*="language-"] {
  color: #FFF;
  background: none;
  border-radius: 0.3rem;

  font-family: "Source Code Pro", Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-weight: 500;
  font-size: 1.4rem;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.6;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;

}

pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #B750B9;
  color: #FFF;
}

pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
code[class*="language-"]::selection, code[class*="language-"] ::selection {
  text-shadow: none;
  background: #B750B9;
  color: #FFF;
}

@media print {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }
}

/* Code blocks
   ========================================================================== */

pre[class*="language-"] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #310541;
}

/* Inline code
   ========================================================================== */

:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
  white-space: normal;
}


.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  font-style: italic;
  font-weight: 400;
  color: #BDC4FF;
}

.token.punctuation {
  color: #C872E7;
}

.namespace {
  opacity: .7;
}

.token.property,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #F4B4E0;
}

.token.tag .token.tag {
  color: #F4B4E0;
}

.token.selector,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #7AC04E;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #F4B4E0;
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: white;
}


.token.class-name,
.token.attr-name {
  color: #F4B4E0;
}

.token.regex,
.token.important,
.token.variable,
.token.function {
  color: #CC556A;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}


/* Command line
   ========================================================================== */

.command-line-prompt {
  border-right: 1px solid #999;
  display: block;
  float: left;
  font-size: 100%;
  letter-spacing: -1px;
  margin-right: 1em;
  pointer-events: none;
  
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.command-line-prompt > span:before {
  color: #999;
  content: ' ';
  display: block;
  padding-right: 0.8em;
}

.command-line-prompt > span[data-user]:before {
  content: "[" attr(data-user) "@" attr(data-host) "] $";
}

.command-line-prompt > span[data-user="root"]:before {
  content: "[" attr(data-user) "@" attr(data-host) "] #";
}

.command-line-prompt > span[data-prompt]:before {
  content: attr(data-prompt);
}
