/* ==========================================================================
  c-contributors
  ---
  List of contributors on home page
   ========================================================================== */

.c-contributors {
  border-top: 0.1rem solid $g-divider-color;
  border-bottom: 0.1rem solid $g-divider-color;
  padding: 6.4rem 0;
  text-align: center;
}

.c-contributors__list {
  margin: 3.2rem 0 0;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}

.c-contributors__list-item {
  flex: 0 0 auto;
  padding: 1.6rem 2.4rem;

  a {
    display: block;
    text-decoration: none;
  }
}

