/* ==========================================================================
  c-hero
  ---
  The intro on the home page
   ========================================================================== */

.c-hero {
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.50) 25%, rgba(255,255,255,0) 50%, rgba(255,255,255,0.50) 75%, rgba(255,255,255,1) 100%),
  url('data:image/svg+xml;utf8,
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" opacity="0.05">
      <pattern id="r" width="340" height="260" patternUnits="userSpaceOnUse">
        <path  transform="scale(.35,.35) translate(0,0)" d=" M 107.05 356.88 L 171.45 237.943 L 270.41 237.878 C 345.625 237.878 345.375 126.937 270.41 126.937 L 14.65 126.937 L 81.29 8 L 271.41 8 L 271.41 8 C 367.686 8 445.85 86.164 445.85 182.44 C 445.85 278.716 367.686 356.88 271.41 356.88 L 271.41 356.88 L 271.41 356.88 L 107.05 356.88 L 107.05 356.88 Z  M 300.94 362.49 L 336.38 397.94 L 363.71 367.95 L 415.93 573.63 L 207.43 526.65 L 239.48 495.25 L 97.11 358.43 L 166.68 229.94 Q 235.849 229.875 270.41 229.875 C 335.5 229.875 335 134.94 270.41 134.94 Q 205.82 134.94 1 134.94 L 76.6 0 L 271.41 0 C 369.829 -0.081 450.359 80.317 453.815 177.871 C 456.998 267.692 389.017 348.079 300.94 362.49 L 300.94 362.49 L 300.94 362.49 Z  M 290.972 363.842 L 336.65 409.52 L 359.61 384.32 L 404.97 562.96 L 223.53 522.08 L 250.97 495.2 L 115.374 364.88 L 271.41 364.88 C 277.949 364.885 284.47 364.539 290.972 363.842 L 290.972 363.842 Z " fill-rule="evenodd" fill="rgb(0,0,0)"/>
      </pattern>
      <pattern id="r2" width="340" height="260" patternUnits="userSpaceOnUse">
        <g transform="scale(-.35,.35) translate(-460 0)">
          <path d=" M 107.05 356.88 L 171.45 237.943 L 270.41 237.878 C 345.625 237.878 345.375 126.937 270.41 126.937 L 14.65 126.937 L 81.29 8 L 271.41 8 L 271.41 8 C 367.686 8 445.85 86.164 445.85 182.44 C 445.85 278.716 367.686 356.88 271.41 356.88 L 271.41 356.88 L 271.41 356.88 L 107.05 356.88 L 107.05 356.88 Z  M 300.94 362.49 L 336.38 397.94 L 363.71 367.95 L 415.93 573.63 L 207.43 526.65 L 239.48 495.25 L 97.11 358.43 L 166.68 229.94 Q 235.849 229.875 270.41 229.875 C 335.5 229.875 335 134.94 270.41 134.94 Q 205.82 134.94 1 134.94 L 76.6 0 L 271.41 0 C 369.829 -0.081 450.359 80.317 453.815 177.871 C 456.998 267.692 389.017 348.079 300.94 362.49 L 300.94 362.49 L 300.94 362.49 Z  M 290.972 363.842 L 336.65 409.52 L 359.61 384.32 L 404.97 562.96 L 223.53 522.08 L 250.97 495.2 L 115.374 364.88 L 271.41 364.88 C 277.949 364.885 284.47 364.539 290.972 363.842 L 290.972 363.842 Z " fill-rule="evenodd" fill="rgb(0,0,0)"/>
        </g>
      </pattern>
      <rect transform="translate(150, 130)" x="0" y="0" width="100%" height="100%" fill="url(%23r)"></rect>
      <rect x="0" y="0" width="100%" height="100%" fill="url(%23r2)"></rect>
    </svg>');
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: $g-bp-2) {
    padding: 9.6rem 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.c-hero__content {
  width: 100%;

  @media (min-width: $g-bp-2) {
    width: 50%;
    margin-right: 4rem;
    margin-bottom: 0;
  }
}

.c-hero__para {
  padding: 0 0 1.6rem 0;
  @media (min-width: $g-bp-2) {
    font-size: 2rem;
  }
}

.c-hero__title {
  font-weight: 600;
  color: $g-heading-color;
  line-height: 1.25;
  font-family: $g-headings-font-family;
  margin: 0 0 1.6rem;
  font-size: 2.8rem;
  @media (min-width: $g-bp-2) {
    font-size: 4rem;
  }
}

.c-hero__illustration {
  background-color: #fff6ff;
  border-radius: 0.8rem;
  width: 100%;
  margin-bottom: 3.2rem;
  padding: 1.6rem;

  @media (min-width: $g-bp-2) {
    padding: 3.2rem 4rem;
    width: 50%;
    border: 0.1rem solid #fd8af7;
    background-color: #fff;
    margin-bottom: 0;
  }
}

.c-hero__illustration img {
  max-width: 100%;
}
