/* ==========================================================================
  c-container-horizontal
   ========================================================================== */

.c-container-horizontal {
  width: 100%;
  max-width: 124.8rem;
  margin: 0 auto;
  padding: 0 2.4rem;
  @media (min-width: $g-bp-2) {
    padding: 0 4.8rem;
  }
}